import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { debounce } from 'lodash'
import './Blogs.scss'
// import { Helmet } from 'react-helmet'
import BlogList from './BlogList'
import {
  DISPLAY_TAG_IDENTIFIER,
  RUMMY_BLOGS_TAG_IDENTIFIER,
} from './constants'
import BlogSearchInput from './BlogSearchInput'
import useCommonFunctionality from './useCommonFunctionality'
// import { generateBlogItemsSchema } from '../../lib/generate-schema'

interface Props {
  tags: string[]
  prefix: string
  blogType?: string
  gameCategory?: string
  excludeRummyPoker?: boolean
  displayFilters?: boolean
  searchOption?: boolean
  title: string | React.ReactNode
}

const Blogs: React.FC<Props> = ({
  tags,
  prefix,
  title,
  blogType,
  displayFilters = true,
  searchOption = true,
}) => {
  const {
    setSearchTermRummy,
    blogs,
  } = useCommonFunctionality({ tags })

  let blogIdentifier: unknown
  const [selectedTag, setSelectedTag] = useState(blogIdentifier)

  let filteredBlogs = blogs
  if (selectedTag !== blogIdentifier) {
    filteredBlogs = filteredBlogs.filter((b: any) =>
      b.tags.reduce((has: boolean, t: any) => {
        if (has) return has
        return t.name === selectedTag
      }, false)
    )
  }

  const displayTags: string[] = blogs.reduce((tgs: string[], b: any) => {
    return [
      ...tgs,
      ...b.tags.reduce((dt: string[], t: any) => {
        if (
          t.name.startsWith(DISPLAY_TAG_IDENTIFIER) &&
          !t.name.includes('display/Rummy') &&
          !t.name.includes('display/Poker') &&
          !tgs.includes(t.name)
        ) {
          return [...dt, t.name]
        }
        return dt
      }, []),
    ]
  }, [])

  const updateSearchTerm = debounce((s: string) => {
    setSelectedTag(blogIdentifier)    
    setSearchTermRummy(s)    
  }, 500)

  const blogItems = filteredBlogs.map((b: any) => {
    return (
      <BlogList
        key={b.id}
        title={b.title}
        slug={b.slug}
        tags={b.tags}
        html={b.html}
        author={b.primary_author.name}
        feature_image={b.feature_image}
        prefix={b.prefix || prefix}
        reading_time={b.reading_time}
        layout='normal'
      />
    )
  })

  // const blogItemsSchema = JSON.stringify(
  //   generateBlogItemsSchema({
  //     blogPrefix: prefix,
  //     blogItems: filteredBlogs,
  //   })
  // )

  return (
    <div id="Blogs">
      <Container>
        {blogType !== 'authors' && (
          <Row>
            <Col>
              <div className="header">
                <h1 className="title">{title}</h1>
                {searchOption && (
                  <BlogSearchInput onChange={s => updateSearchTerm(s)} />
                )}
              </div>
            </Col>
          </Row>
        )}

        <Row>
          {displayFilters && (
            <div className="col-12 display-pills">
              <button
                type="button"
                className={`tag-pill ${
                  selectedTag === blogIdentifier && 'selected'
                }`}
                onClick={() => {
                  setSelectedTag(blogIdentifier)
                }}
              >
                All
              </button>
              {displayTags.map(d => {
                return (
                  <button
                    key={d}
                    type="button"
                    className={`tag-pill ${selectedTag === d && 'selected'}`}
                    onClick={() => {
                      setSelectedTag(d)
                    }}
                    title={d.replace(DISPLAY_TAG_IDENTIFIER, '')}
                  >
                    {d.replace(DISPLAY_TAG_IDENTIFIER, '')}
                  </button>
                )
              })}
            </div>
          )}
          <Col>
            {blogType !== 'authors' && (
              <div className="blogs-count">
                {`${filteredBlogs?.length} Article${
                  filteredBlogs.length > 1 ? 's' : ''
                } found in 
              this Guide`}
              </div>
            )}
            {/* <Helmet>
              <script type="application/ld+json">{blogItemsSchema}</script>
            </Helmet> */}
            <div className="blogs-list">{blogItems}</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Blogs
