const RUMMY_URL = 'learn'
const rummyTagsAndPrefixes = [
  {
    tags: ['GM_Rummy'],
    prefix: `${RUMMY_URL}`,
    one_link:
      'https://play.google.com/store/apps/details?id=com.mega.app.rummy',
  },
]

const allBlogTags = [...rummyTagsAndPrefixes.flatMap(t => t.tags)]

const getAllBlogs = (posts, tagsAndPrefixes) => {
  const allBlogs = []
  for (let i = 0; i < posts.length; i += 1) {
    const post = posts[i]
    const postTags = post.node.tags.map(tg => tg.name)
    const tnp = tagsAndPrefixes.find(e =>
      e.tags.some(tag => postTags.includes(tag))
    )
    if (tnp) {
      allBlogs.push({ ...post.node, prefix: tnp.prefix })
    }
  }

  return allBlogs
}

module.exports = {
  RUMMY_URL,
  rummyTagsAndPrefixes,
  allBlogTags,
  getAllRummyBlogs: posts => getAllBlogs(posts, rummyTagsAndPrefixes),
}
