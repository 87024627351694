import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'
import useSearch from '../../hooks/useSearch'
import {
  getAllRummyBlogs,
  // eslint-disable-next-line
  // @ts-ignore
} from '../../lib/ghost-blogs'

interface Props {
  tags: string[]
}
// filtering out vernacular blog posts & staging posts from nodal page
const useCommonFunctionality = ({ tags }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(
        sort: { order: DESC, fields: [published_at] }
        filter: {
          tags: {
            elemMatch: {
              slug: {
                nin: [
                  "hash-lang_hindi"
                  "hash-lang_telugu"
                  "hash-lang_gujarati"
                  "hash-lang_marathi"
                  "hash-lang_tamil"
                  "hash-staging"
                ]
              }
            }
          }
        }
      ) {
        edges {
          node {
            id
            slug
            title
            reading_time
            html
            feature_image
            primary_author {
              name
            }
            tags {
              name
              slug
            }
          }
        }
      }
    }
  `)

  const [searchTermRummy, setSearchTermRummy] = useState('')
  const [blogs, setBlogs] = useState<any>([])
  const [allRummyBlogs, setAllRummyBlogs] = useState<any>([])
  const [indexFields] = useState(['title'])

  useEffect(() => {
    const arb = getAllRummyBlogs(data.allGhostPost.edges)

    const blgs = data.allGhostPost.edges
      .map((b: any) => b.node)
      .filter((e: any) => {
        return e.tags.reduce((has: boolean, t: any) => {
          if (has) return has

          return tags.includes(t.name)
        }, false)
      })

    setBlogs(blgs)
    setAllRummyBlogs(arb)
  }, [tags, data.allGhostPost.edges])

  const searchRummy = useSearch({
    searchName: 'title',
    documents: allRummyBlogs,
    indexFields,
  })

  let filteredBlogs = blogs
  
  if (searchTermRummy) {
    filteredBlogs = searchRummy.search(searchTermRummy)
  }

  return {
    searchTermRummy,
    setSearchTermRummy,
    blogs: filteredBlogs,
  }
}

export default useCommonFunctionality
