import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Blogs from '../components/Blogs/Blogs'
import { RUMMY_LEARN } from '../components/internal-links'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Learn Rummy',
    url: RUMMY_LEARN,
  },
]

const Learn: React.FC = () => {
  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
      <SEO
        title="Rummy Gameplay: Top websites and strategies to play Rummy | Mega"
        description="Best way to play rummy is tough to find, isn't it? Why worry when we have listed the best websites, strategies, and simple gameplay. Read now."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="rummy"
        tags={['GM_Rummy']}
        prefix={`${RUMMY_LEARN}` || '/'}
        title={
          <>
            Learn Rummy - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default Learn
